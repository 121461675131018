<template>
  <div>
    <TopNavVue/>
    <div>
      <el-row class="cloudDesktop">
        <el-col :span="22" :offset="1" class="wga">
          <!-- 页面标题 -->
          <h6 class="title">磐石信创生态圈</h6>
          <p>围绕信创规范，建构磐石生态。磐石与国内主流软硬件解决方案厂家展开全面适配，适配对象涉及各主流国产终端操作系统、版式、流式应用和各类外设产品等，通过适配动作持续优化产品形态和兼容模式，同时与更多云衍生产品相融合，为用户提供更加完善的云桌面解决方案和更加广泛的应用场景。</p>
          <p>除加强应用层级软件适配外，磐石面向底层计算资源也开展深度合作，已经与包括nutanix、vmware、SmartX、青云等国内外主流底层虚拟资源平台开展合作，为用户提供更加丰富的底层选择空间。</p>
          <img src="@/assets/xcst/s2.png" />
        </el-col>

        <el-col :span="22" :offset="1" class="wga">
          <h6 class="title">磐石云桌面与主流信创操作系统适配互认</h6>
          <p>磐石云桌面及其相关软件应用产品拥有独立知识产权，并先后完成了与统信、麒麟等国产操作系统的相互适配认证。现阶段正在积极开展与各种类别信创应用软件和国产外设的全面适配。</p>
          <img src="@/assets/xcst/s3.jpg" />
        </el-col>

        <el-col :span="22" :offset="1" class="wga">
          <h6 class="title">磐石云桌面支持全面托管SmartX超融合平台</h6>
          <p>磐石云桌面与smartx达成战略合作，磐石云桌面控制引擎支持全面托管smartx超融合资源，同时在整个云桌面的创建、发布、管理过程中，支持对smartx资源支持全面接管其配置，支持通过控制引擎对其进行全面管理。</p>
          <img src="@/assets/xcst/aq40.jpg" />
        </el-col>
      </el-row>
    </div>
    <footerMobileVue/>
  </div>
</template>

<script>
import TopNavVue from '../../components/topNav.vue'
import footerMobileVue from '../../components/footerMobile.vue';
export default {
  name:'xcstMobile',
  components:{footerMobileVue,TopNavVue},

  
}
</script>

<style scoped>
  .cloudDesktop{background: #f5f5f5;padding-top: 17%;padding-bottom: 20px;}
  .title{font-weight: normal;font-size: 4.65vw;border-left: 5px solid #E32121;padding-left: 10px;}
  .wga{padding-top: 10px;}
  .stc p span{display: inline-block;width: 8px;height: 8px;border-radius: 4px;background: #888;margin-right: 5px;}
  .wga p{font-size: 3vw;color: #888;width: 100%;margin: 15px auto;text-indent: 2em;}
  .wga img{width: 100%;}
</style>